import React from 'react';
import './Down.css';

const down = () => {
    return (
        <div className="normal-page">
            <h1>Welcome to Our Summer Camp Website!</h1>
            <p>Hey there! Our exciting summer camp adventures are taking a short break to gear up for even more fun next
                year! Stay tuned for updates and get ready to embark on new unforgettable experiences. See you soon
                under the summer sun!</p>
            <p>Visit: <a href="https://www.machaxi.com" target="_blank"
                                               rel="noopener noreferrer">machaxi.com</a></p>
        </div>
    );
};

export default down;
